<template>
	<b-autocomplete
		placeholder="İş emri seçiniz.."
		field="name"
		icon="search"
		size="is-small"
		v-model="value.name"
		:data="productionorders"
		:loading="$apollo.queries.readProductionOrder.loading"
		:disabled="disabled"
		@select="productionorders_select"
		@typing="productionorders_read"
		@focus="productionorders_focus"
		clearable
		open-on-focus
	>
		<template slot="empty">Sonuç bulunamadı.</template>

		<template slot="header"> </template>

		<template slot-scope="props">
			<div class="media">
				<div class="media-left">
					<img width="32" src="@/assets/e.png" />
				</div>

				<div class="media-content">
					{{ props.option.name }}
					<br />
					{{ props.option.material.code }} - {{ props.option.material.name }}
					<b> {{ props.option.quantity }} KG </b>
					<br />
					<small>
						{{
							new Date(props.option.planstart).toLocaleDateString("tr-TR", {
								weekday: "long",
								year: "numeric",
								month: "numeric",
								day: "numeric",
							})
						}}
						~
						{{
							new Date(props.option.planend).toLocaleDateString("tr-TR", {
								weekday: "long",
								year: "numeric",
								month: "numeric",
								day: "numeric",
							})
						}}
					</small>
				</div>
			</div>
		</template>
	</b-autocomplete>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "ProductionOrderAutofill",
	props: ["value", "disabled"],
	data: () => ({
		productionorders: [],
	}),
	methods: {
		productionorders_focus() {
			this.productionorders_read();
		},
		productionorders_read() {
			this.$apollo.queries.readProductionOrder.start();
		},
		productionorders_select(opt) {
			Object.assign(this.value, opt);
		},
	},
	apollo: {
		readProductionOrder: {
			query: gql`
				query readProductionOrders($config: String) {
					readProductionOrders(config: $config) {
						id
						name
						material {
							id
							code
							name
						}
						quantity
						planstart
						planend
					}
				}
			`,
			variables() {
				return {
					config: JSON.stringify({
						skip: 0,
						take: 10,
						sorts: [],
						filters: {
							name: this.value.name ? "%" + this.value.name + "%" : "%",
							status: "Üretimde",
						},
					}),
				};
			},
			update(data) {
				this.productionorders = data.readProductionOrders;
			},
		},
	},
};
</script>
