var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-autocomplete',{attrs:{"placeholder":"İş emri seçiniz..","field":"name","icon":"search","size":"is-small","data":_vm.productionorders,"loading":_vm.$apollo.queries.readProductionOrder.loading,"disabled":_vm.disabled,"clearable":"","open-on-focus":""},on:{"select":_vm.productionorders_select,"typing":_vm.productionorders_read,"focus":_vm.productionorders_focus},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('img',{attrs:{"width":"32","src":require("@/assets/e.png")}})]),_vm._v(" "),_c('div',{staticClass:"media-content"},[_vm._v("\n\t\t\t\t"+_vm._s(props.option.name)+"\n\t\t\t\t"),_c('br'),_vm._v("\n\t\t\t\t"+_vm._s(props.option.material.code)+" - "+_vm._s(props.option.material.name)+"\n\t\t\t\t"),_c('b',[_vm._v(" "+_vm._s(props.option.quantity)+" KG ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',[_vm._v("\n\t\t\t\t\t"+_vm._s(new Date(props.option.planstart).toLocaleDateString("tr-TR", {
							weekday: "long",
							year: "numeric",
							month: "numeric",
							day: "numeric",
						}))+"\n\t\t\t\t\t~\n\t\t\t\t\t"+_vm._s(new Date(props.option.planend).toLocaleDateString("tr-TR", {
							weekday: "long",
							year: "numeric",
							month: "numeric",
							day: "numeric",
						}))+"\n\t\t\t\t")])])])]}}]),model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}},[_c('template',{slot:"empty"},[_vm._v("Sonuç bulunamadı.")]),_vm._v(" "),_c('template',{slot:"header"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }