<template>
    <section>
        <div class="container">
            <TitleBar title="Üretim Onayı Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="productionconfirms.filters.id"></b-input>
                </b-field>
                <b-field class="column is-3" label="Açıklama">
                    <b-input v-model="productionconfirms.filters.name"></b-input>
                </b-field>
                <b-field class="column is-4" label="İş Emri">
                    <ProductionOrderAutofill v-model="productionconfirms.filters.productionorder" />
                </b-field>
                <b-field class="column is-3" label="Malzeme Kodu">
                    <MaterialAutofill v-model="productionconfirms.filters.material" />
                </b-field>
                <b-field class="column is-3" label="Parti Numarası">
                    <b-input v-model="productionconfirms.filters.lotnum"></b-input>
                </b-field>
                <b-field class="column is-3" label="Iş Merkezi">
                    <b-input v-model="productionconfirms.filters.workplace"></b-input>
                </b-field>
                <b-field class="column is-3" label="Üretim Başlangıç">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="productionconfirms.filters.prodstart" range> </b-datepicker>
                </b-field>
                <b-field class="column is-3" label="Üretim Bitiş">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="productionconfirms.filters.prodend" range> </b-datepicker>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Üretim Onayı Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="productionconfirms_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="productionconfirms_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al"  @click="productionconfirms_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!productionconfirms.selected.id" @click="productionconfirms_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!productionconfirms.selected.id" @click="productionconfirms_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!productionconfirms.selected.id" @click="productionconfirms_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="productionconfirms_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="productionconfirms_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :data="productionconfirms.data"
                    :total="productionconfirms.total"
                    :mobile-cards="false"
                    :current-page.sync="productionconfirms.page"
                    :selected.sync="productionconfirms.selected"
                    :sort-multiple-data.sync="productionconfirms.sorts"
                    :loading="$apollo.queries.readProductionConfirms.loading"
                    @dblclick="productionconfirms_show"
                    @sort="productionconfirms_sort"
                    @sorting-priority-removed="productionconfirms_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Üretim onayı kaydı bulunamadı.
                        </div>
                    </template>
                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>
                    <b-table-column field="productionorder.name" label="İş Emri Açıklaması" v-slot="props">{{ props.row.productionorder.name }}</b-table-column>
                    <b-table-column field="material.code" label="Malzeme Kodu" v-slot="props">{{ props.row.productionorder.material.code }}</b-table-column>
                    <b-table-column field="material.name" label="Malzeme Adı" v-slot="props">{{ props.row.productionorder.material.name }}</b-table-column>
                    <b-table-column field="lotnum" label="Parti No" v-slot="props">{{ props.row.lotnum }}</b-table-column>
                    <b-table-column field="productionorder.workplace" label="İş Merkezi" v-slot="props">{{ props.row.productionorder.workplace }}</b-table-column>
                    <b-table-column field="quantity" label="Onay Miktarı (KG)" v-slot="props" sortable>{{ props.row.quantity }} KG</b-table-column>
                    <b-table-column field="prodstart" label="Üretim Başlangıç" v-slot="props" sortable>{{
                        new Date(props.row.prodstart).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        })
                    }}</b-table-column>
                    <b-table-column field="prodend" label="Üretim Bitiş" v-slot="props" sortable>{{
                        new Date(props.row.prodend).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        })
                    }}</b-table-column>
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import MaterialAutofill from "@/components/MaterialAutofill.vue";
import ProductionOrderAutofill from "@/components/ProductionOrderAutofill.vue";

export default {
    name: "ProductionConfirm",
    components: {
        TitleBar,
        MaterialAutofill,
        ProductionOrderAutofill,
    },
    data: () => ({
        modi: "",
        productionconfirms: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                material: {},
                productionorder: {},
                prodstart:[],
                prodend:[]
            },
            selected: {},
        },
    }),
   mounted(){
        this.productionconfirms_read();
    },
    methods: {
        productionconfirms_read() {
            this.$apollo.queries.readProductionConfirms.start();
            this.$apollo.queries.readProductionConfirms.refetch();
        },
        productionconfirms_new() {
            this.$router.push({ path: "/uretim/onay/ekle" });
        },
        productionconfirms_ref() {
            this.$router.push({
                path: "/uretim/onay/ekle",
                query: { id: this.productionconfirms.selected.id, modi: "ref" },
            });
        },
        productionconfirms_show() {
            this.$router.push({
                path: "/uretim/onay/detay",
                query: { id: this.productionconfirms.selected.id, modi: "show" },
            });
        },
        productionconfirms_edit() {
            this.$router.push({
                path: "/uretim/onay/detay",
                query: { id: this.productionconfirms.selected.id, modi: "edit" },
            });
        },
        productionconfirms_delete() {},
        productionconfirms_print() {},
        productionconfirms_export() {},
        productionconfirms_sort(field, order) {
            this.productionconfirms_nosort(field);
            this.productionconfirms.sorts.push({ field, order });
        },
        productionconfirms_nosort(field) {
            this.productionconfirms.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        },
    },
    apollo: {
        readProductionConfirms: {
            query: gql`
                query readProductionConfirms($config: String) {
                    readProductionConfirms(config: $config) {
                        id
                        productionorder {
                            id
                            name
                            material {
                                id
                                code
                                name
                            }
                            workplace
                        }
                        lotnum
                        quantity
                        prodstart
                        prodend
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.productionconfirms.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.productionconfirms.filters) if (!this.productionconfirms.filters[filter]) delete this.productionconfirms.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.productionconfirms.take,
                        filters: this.productionconfirms.filters,
                        skip: (this.productionconfirms.page - 1) * this.productionconfirms.take,
                    }),
                };
            },
            update(data) {
                this.productionconfirms.data = data.readProductionConfirms;
            },
        },
    },
};
</script>
